import { BrandButton } from "@components/reusables/brandButton";
import { IInformationSection } from "@interface/propsInterface";
import { ISProgramSectionProps } from "@interface/styledComponentInterface";
import { color, screenBreakPoint } from "@styles/styleVariables";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { ButtonEnum } from "src/enums/buttonsEnum";
import styled from "styled-components";
import { motion } from "framer-motion";
import { pushItemUp } from "@styles/animations";

export const ProgramSection = ({
  name,
  briefDescription,
  strapiId,
  rank,
  coverImage,
  url,
  useTemplate,
}: IInformationSection) => {
  return (
    <motion.div variants={pushItemUp} whileInView="after" initial="before">
      <SProgramSection even={(Number(rank) + 1) % 2 == 0}>
        <div className="program-section-description">
          <h2>{name ?? ""}</h2>
          <p>{briefDescription ?? ""}</p>
          {useTemplate ? (
            <BrandButton
              link={url ?? ""}
              name="Learn More!"
              id={strapiId ?? ""}
              category={ButtonEnum.Tertiary}
            ></BrandButton>
          ) : (
            <></>
          )}
        </div>
        <div className="program-section-image-wrapper">
          <GatsbyImage
            image={coverImage ?? ""}
            alt={name ?? ""}
            className="program-section-image"
          ></GatsbyImage>
        </div>
      </SProgramSection>
    </motion.div>
  );
};

const SProgramSection = styled.div<ISProgramSectionProps>`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 5rem 0rem;
  flex-direction: ${(props) => (props.even ? "row-reverse" : "row")};

  .program-section-image-wrapper {
    min-width: 50%;
    max-width: 50%;
    padding: 2rem;
    .program-section-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .program-section-description {
    h2,
    p {
      margin-bottom: 2rem;
    }

    h2 {
      color: ${color.oxfordBlue};
    }
  }

  @media (max-width: ${screenBreakPoint.sm}) {
    flex-direction: column;
    align-items: flex-start;
    .program-section-image-wrapper {
      min-width: 100%;
      padding: 2rem 0rem;
    }
  }
`;
