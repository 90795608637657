import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import { ProgramSection } from "./programSection";

export const ProgramContent = () => {

  const query = useStaticQuery(graphql`
    query programInformationQuery {
      programInformationQuery: allStrapiProgramInformation(sort: {fields: rank, order: ASC}) {
        nodes {
          briefDescription
          strapi_id
          rank
          name
          useTemplate
          coverImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
          }
          sublink {
            url
            name
            main_link {
              url
              name
            }
          }
        }
      }
    }
  `)

  const programInformationQuery = query.programInformationQuery.nodes;

  return (
    <SProgramsContent>
      {programInformationQuery.map((p:any) => 
        <ProgramSection
          name={p?.name ?? ""}
          briefDescription={p?.briefDescription ?? ""}
          strapiId={p?.strapi_id ?? ""}
          coverImage={p?.coverImage?.localFile?.childImageSharp?.gatsbyImageData ?? ""}
          url={`${p?.sublink?.main_link?.url ?? ""}${p?.sublink?.url ?? ""}`}
          buttonName={p?.sublink?.name ?? ""} 
          useTemplate={p?.useTemplate ?? false}
          rank={p?.rank ?? "9999"}
        ></ProgramSection>
      )}
    </SProgramsContent>
  )
}

const SProgramsContent  = styled.div`
  min-height: 100vh;
`