import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import { Layout } from "@components/layout";
import { PageHeader } from "@components/reusables/pageHeader";
import { ProgramContent } from "@components/programs/programContent";
import { HeadMetadata } from "@components/headMetadata";

const ProgramsIndex = () => {
  
  const query = useStaticQuery(graphql`
    query programsPageQuery {
      pageHeaderQuery: strapiPageHeader(main_link: {url: {eq: "/programs"}}){
        id
        name
        description
        strapi_id
        coverImageGreyScale
        coverImageBlur
        coverImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
        }
      }
    }
  `)

  const pageHeaderQuery = query.pageHeaderQuery;

  return (
    <Layout>
      <HeadMetadata location="Programs"></HeadMetadata>
      <PageHeader
        name={pageHeaderQuery.name}
        description={pageHeaderQuery.description}
        coverImageGreyScale={pageHeaderQuery.coverImageGreyScale}
        coverImageBlur={pageHeaderQuery.coverImageBlur}
        coverImage={pageHeaderQuery.coverImage.localFile.childImageSharp.gatsbyImageData}
      ></PageHeader>
      <div className="content-wrapper">
        <ProgramContent></ProgramContent>
      </div>
    </Layout>
  )
};

export default ProgramsIndex;